import React from "react";
import TaskForm from "../../components/Tasks/TaskForm";

const CreateTask = () => {
  return (
    <>
      <div>CreateTask</div>
      <TaskForm />
    </>
  );
};

export default CreateTask;
